<template>
  <div class="w-100 h-100 row m-0">
    <div class="col-xl-7 d-none d-xl-flex bg-login" data-bg="/img/background-login.webp">
      <div
        class="position-relative w-75"
        style="height: 450px; max-height: 750px; margin-top: 150px; margin-left: 25px;"
      >
        <img
          src="@/assets/img/pulih-logo-login.png"
          alt=""
          style="width: 155px; height: 75px; object-fit: cover;"
          class="mb-5"
        />
        <p class="mb-5 custom-text-login">
          PULIH is an application to help people access the PAP Program from AstraZeneca and the
          application is integrated with the dashboard. In this project, we also create integrated
          dashboard for the report of Patients activities.
        </p>
        <a
          href="https://play.google.com/store/apps/details?id=com.astrazeneca.pulih&hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="@/assets/img/google-play-login.png"
            alt="Google Play Login"
            style="width: 165px; height: 50px; object-fit: cover; cursor: pointer;"
          />
        </a>

        <!-- <div class="h-100">
        <img
        src="@/assets/img/background-login.png"
        alt=""
        class="w-100 h-100"
        style="object-fit: cover;"
        />
      </div> -->
      </div>
    </div>
    <div class="col-xl-5 col-12 p-0">
      <div
        class=" d-flex d-xl-none align-items-center justify-content-start py-3 "
        style="position: absolute; display: flex;flex-direction: column;align-items: start; justify-content: start;top: 50px;width: 175px;"
      >
        <div class="bg-primary w-100 pl-4 py-3 px-5" style="border-radius: 0 40px 40px 0;">
          <img src="@/assets/img/pulih-logo-login.png" alt="" style="width: 80px;height: auto; " />
        </div>
      </div>

      <div
        class="d-flex justify-content-center align-items-center flex-column bg-white h-100 w-100 "
      >
        <div class="px-4 p-xl-0 custom-container-login" style="padding-top: 130px;">
          <!-- <img class="m-auto" width="200" src="../../../assets/img/anita-besar.png" /> -->

          <!-- <div class="text-dark font-size-32 mb-3 text-center"><strong>Login MODC</strong></div> -->
          <!-- TO DO DUMMY, UNCOMMENT THIS BLOCK -->
          <p class="welcome-title ">
            Selamat Datang!
          </p>
          <a-form
            :model="loginForm"
            :rules="rules"
            layout="vertical"
            class="my-4"
            @finish="handleFinish"
            @finishFailed="handleFinishFailed"
          >
            <a-form-item name="email" class="custom-form-item" label="Email">
              <a-input
                v-model:value="loginForm.email"
                placeholder="Masukkan email anda"
                class="custom-input-email-login"
              />
            </a-form-item>
            <a-form-item name="password" label="Kata Sandi" class="custom-form-item">
              <a-input-password
                v-model:value="loginForm.password"
                placeholder="Masukkan sandi anda"
                class="custom-input-password-login"
              />
            </a-form-item>
            <p class="text-right mb-5 w-100">
              <router-link to="forgot-password" class="custom-forget-pass"
                >Lupa kata sandi?</router-link
              >
            </p>

            <p class="text-center mb-5 w-100">
              Pengguna baru?
              <router-link to="register" class="custom-register-link">Daftar disini</router-link>
            </p>
            <!-- TO DO DUMMY FUNCTION, UNCOMMENT BLOCK/LINE BELOW & COMMENT BLOCK/LINE AFTER -->
            <!-- <a-button type="primary" html-type="submit" class="text-center w-100" :loading="loading"> -->
            <a-button
              type="primary"
              html-type="submit"
              class="custom-button-login"
              :loading="loading"
            >
              <strong>Masuk</strong>
            </a-button>
            <p class="text-center mt-4 mb-1 w-100 d-xl-none">
              Atau
            </p>
            <div
              class="w-100 d-flex align-items-center justify-content-center d-xl-none"
              style="border-radius: 8px;"
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.astrazeneca.pulih&hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="@/assets/img/google-play-login-mobile.png"
                  alt=""
                  style="width: auto;height: 68px; "
                />
              </a>
            </div>
          </a-form>
        </div>
      </div>
    </div>

    <!-- <div class="text-center pt-2 mb-auto">
      <span class="mr-2">Don't have an account?</span>
      <router-link to="/auth/register" class="vb__utils__link">
        Sign up
      </router-link>
    </div> -->
  </div>
</template>
<script>
import { computed, reactive, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import cookies from 'vue-cookies'

export default {
  name: 'ThLogin',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const settings = computed(() => store.getters.settings)
    const loading = computed(() => store.getters['user/user'].loading)
    // const cookies = ref(null)
    const rules = {
      email: [
        {
          required: true,
          message: 'Tolong masukkan email!',
          trigger: 'change',
        },
      ],
      password: [{ required: true, message: 'Tolong masukkan sandi!', trigger: 'change' }],
    }
    const loginForm = reactive({
      email: '',
      password: '',
    })

    const changeAuthProvider = value => {
      store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    }
    const lazyLoadBackground = () => {
      const lazyBackgrounds = document.querySelectorAll('.bg-login')

      if ('IntersectionObserver' in window) {
        const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const bgElement = entry.target
              const bgUrl = bgElement.getAttribute('data-bg')
              bgElement.style.backgroundImage = `url(${bgUrl})`
              observer.unobserve(bgElement)
            }
          })
        })

        lazyBackgrounds.forEach(bg => observer.observe(bg))
      } else {
        // Fallback untuk browser lama
        lazyBackgrounds.forEach(bgElement => {
          const bgUrl = bgElement.getAttribute('data-bg')
          bgElement.style.backgroundImage = `url(${bgUrl})`
        })
      }
    }
    onMounted(() => {
      lazyLoadBackground()
    })
    const handleFinish = async values => {
      console.log(values)
      const data = await store.dispatch('user/LOGIN', { ...values })
      const user = data.user || data
      console.log(data, '<<<<<<DATA')
      localStorage.setItem('accessToken', user.accessToken)
      cookies.set('sessionId', user.accessToken)
      // this.$cookies.set('sessionId', token, null, '/')
      // const sessionId = this.$cookies.get('sessionId')
      // console.log(sessionId)
      store.commit('user/SET_STATE', {
        // name: user.name,
        email: user.email,
        sessionId: user.accessToken,
        role: user.role,
      })
      cookies.set('user', user, null, '/')

      router.push('/')

      notification.success({
        message: 'Selamat Datang ' + user.name,
        duration: 5,
      })
    }
    const handleFinishFailed = errors => {
      console.log(errors)
    }

    return {
      settings,
      loading,
      rules,
      loginForm,
      changeAuthProvider,
      handleFinish,
      handleFinishFailed,
    }
  },

  methods: {
    // pushToBanner() {
    // this.$router.push('banner')
    // },
  },
  // data: function () {
  //   return {
  //     rules: {
  //       email: [{ required: true, message: 'Tolong masukkan username!', trigger: 'change' }],
  //       password: [{ required: true, message: 'Tolong masukkan sandi!', trigger: 'change' }],
  //     },
  //     loginForm: {
  //       email: 'demo@visualbuilder.cloud',
  //       password: 'VisualBuilder',
  //     },
  //   }
  // },
  // computed: {
  //   ...mapState(['settings']),
  //   loading() {
  //     return this.$store.state.user.loading
  //   },
  // },
  // methods: {
  //   changeAuthProvider(value) {
  //     this.$store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
  //   },
  //   handleFinish(values) {
  //     this.$store.dispatch('user/LOGIN', { payload: values })
  //   },
  //   handleFinishFailed(errors) {
  //     console.log(errors)
  //   },
  // },
}
</script>
<style lang="scss">
.custom-input-email-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;
  &:focus {
    border-bottom: 1px solid #800152 !important;
  }
  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}
.custom-input-password-login {
  border: none !important;
  border-bottom: 1px solid #e4e9f0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  &:focus-within {
    border-bottom: 1px solid #800152 !important;
  }
  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}
.custom-forget-pass {
  color: #800152 !important;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;
  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}
.custom-register-link {
  color: #800152 !important;
  font-weight: 700;
  border-bottom: 1px solid transparent;
  transition: 0.3s all ease-in-out;
  &:hover {
    border-bottom: 1px solid #800152 !important;
  }
}
.custom-form-item {
  label::before {
    content: '' !important;
  }
}
.custom-button-login {
  width: 100%;
  height: 68px !important;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}
.custom-container-login {
  width: 75% !important;
}
.custom-text-login {
  color: white;
}
@media (max-width: 960px) {
  .custom-container-login {
    width: 100% !important;
  }
}

.welcome-title {
  color: #800152;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 24px;
}
@media (max-width: 600px) {
  .welcome-title {
    font-size: 20px;
  }
}
.bg-login {
  background-image: url('/img/background-login.webp');
  background-position: 0;
  background-size: cover;
}
</style>
